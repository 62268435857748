@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.1.2/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  /* font-family: National2, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6f6f6; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: National2, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300; */
  font-family: 'Poppins', sans-serif;
}

input {
  padding-left: 10px;
}

.demo-wrapper {
  background: white;
  border: 1px solid #d9d9d9;
}

.demo-editor {
  padding: 0 10px;
  min-height: 150px;
}

b,
strong {
  font-weight: bold;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: 'Poppins', sans-serif;
}

button {
  text-transform: lowercase;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container img {
  width: 100%;
  margin: 0 auto;
}

.image-container img:focus {
  outline: none;
}

.render-preview {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  outline: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}
.custom-dialog {
  min-width: 900px;
  position:relative;
}